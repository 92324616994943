* {
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
}

.card {
    display: flex;
    flex: 0 0 80px;
    flex-direction: column;
    text-align: left;
    margin: 5px 0;
}

.date {
    font-size: 18px;
    color: #A0A0A0;
    font-weight: 600;
}

.value {
    font-weight: 700;
    color: #ffffff;
    background-color: #B586FA;
    width: fit-content;
    min-width: 80px;
    padding-left: 5px;
    padding-right: 5px;
}

/*
@media (max-width: 768px) {
    .card{
        flex: 0 0 50px;
    }

    .date {
        font-size: 12px;
    }

    .value{
        font-size: 20px;
        padding: 10px 3px;
    }
}
*/

.pageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: Inter, sans-serif;
    background: url('../../public/background.svg') no-repeat center center fixed;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 80vh;
    gap: 120px;
}

.logoContainer, .textContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    width: 50%;
}

.logoContainer {
    height: auto;
    align-items: center;
}

.textContent {
    font-size: calc(2rem + 1vw);
    margin-bottom: 20px;
    text-align: left;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.title {
    font-size: calc(2rem + 1vw);
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold;
}

.description {
    display: flex;
    width: 100%;
    font-size: calc(1rem + 0.5vw);
    line-height: 1.6;
    margin-bottom: 20px;
    overflow-wrap: break-word;
    text-wrap: wrap;
}

.notice {
    display: flex;
    width: 100%;
    font-size: calc(1rem + 0.5vw);
    line-height: 1.6;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    text-wrap: wrap;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .textContent {
        padding: 0;
    }

    .logo {
        width: 100px;
        height: 100px;
    }

    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
        text-align: center;
    }

    .notice {
        font-size: 0.9rem;
        text-align: center;
    }
}
.headerButtonsContainer {
    gap: 16px;
}

@media (width <= 1100px){
    .headerButtonsContainer{
        flex-direction: column;
    }
}

.button {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;

    width: 180px;
    height: 50px;

    font-size: 15px;
    font-weight: 600;

    cursor: pointer;

    background-color: #9c88ff;
    color: #fff;

    transition: background-color 0.3s;
    &:hover {
        background-color: #8e7ccc;
    }
}

.redButton {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;

    width: 180px;
    height: 50px;

    font-size: 15px;
    font-weight: 600;

    cursor: pointer;

    background-color: #F16868;
    color: #fff;

    transition: background-color 0.3s;
    &:hover {
        background-color: #f15151;
    }
}

.inputsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 0;
}

.inputField {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: none;
    background-color: #f1f1f1;
    font-size: 16px;
    flex: 1;
}

.groupsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0;
    overflow-x: auto;

    @media (width <= 1000px) {
        grid-template-columns: repeat(2, 2fr);
    }

    @media (width <= 600px) {
        grid-template-columns: repeat(1, 2fr);
    }
}

.groupCard {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    min-width: 250px;
    height: 187px;
}
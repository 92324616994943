.subject {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.subjectInfo {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    min-width: 300px;
    height: 187px;
}

.tableDiv {
    overflow-x: auto;
}

.table {
    width: 100%;
    display: table-row-group;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    text-align: center;
}

.tr {
}

.table .th {
    font-size: 15px;
    padding: 5px;
    border-right: 2px solid #eff0f9;
    color: #A0A0A0;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.table .th:first-child {
    border-radius: 5px 0 0 0;
}

.table .th:last-child{
    border-right: 0;
    border-radius: 0 5px 0 0;
}


.table .td {
    border-right: 2px solid #eff0f9;
    font-size: 25px;
    padding: 5px;
    color: #ffffff;
    font-weight: 700;
}

.table .td:last-child{
    border-right: 0;
    border-radius: 0 0 5px 0;
}

.table .td:first-child{
    border-radius: 0 0 0 5px;
}

.tableLabel {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    padding: 5px 5px 5px 20px;
    margin: 0;
}

.tableAndTableLabel {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    margin: 16px 0;
    overflow: hidden;
    justify-content: space-between;
}

.tablePlaceHolder {
    margin: 0;
    height: 100%;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #A0A0A0;
    display: flex;
    align-items: center;
    overflow: auto;
}

.mobileSubjectLabel {
    background-color: #ffffff;
    border-radius: 15px;
    width: 100%;
    padding-left: 11px;
}
.signIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 377px;
    height: 350px;
    padding: 0;
}

.signUp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    width: 377px;
    height: 500px;
    padding: 0;
}

.inputField {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    background-color: #f0f0f0;
    font-size: 20px;
    color: #333;
    outline: none;
}

.clarification {
    font-size: 15px;
    color: #A0A0A0;
    font-weight: 600;
}

.registerLink {
    font-size: 15px;
    font-weight: 700;
    color: #888888;
    text-decoration: underline;
    cursor: pointer;
}

.redAlert {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    border-radius: 15px;

    padding: 10px 30px;

    font-size: 15px;
    font-weight: 400;

    background-color: #F16868;
    color: #fff;
}

.checkbox {

}

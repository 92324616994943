.searchBar {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    background-color: #f0f0f0;
    font-size: 20px;
    color: #333;
    outline: none;
}
